<template>
    <div>
        <div class="text-xs-right" >
            <v-btn class="white--text elevation-6" small round color="teal darken-2" to="/njangi/withdrawals/history">
                <span> My Withdrawal History</span>
            </v-btn>
        </div>
        <h2 class="elevation-4 primary darken-2 white--text font-weight-bold subheading py-2 text-xs-center">REQUEST A WITHDRAWAL</h2>

        <div class="elevation-4 white lighten-4">
            <p class="dark--text subheading pa-2 ma-0"><b>Principal Balance:</b> <span> {{campaign.saved_amount}} FCFA</span></p>
            <v-divider></v-divider>
        </div>
        
        <v-card color="elevation-4 white pa-1" style="" >
            <p class="dark--text caption pt-2 ma-0 text-xs-center">The withdrawal will be validated in at most 48 hrs and the money sent to your number below</p>
            <v-layout row>
                <v-flex xs12 md10 offset-md1>            
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-money-bill fa-2x mt-2"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field
                                class="pa-0 mt-2"
                                id="withdraw_amount" ref="withdraw_amount" v-model="withdraw_amount" v-on:input="getWithdrawalFee()"
                                label="Input amount (F CFA)"
                                :rules="[v => !!v || 'Your requested amount is required']"
                                required
                                color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); withdraw_amount_help = true; getWithdrawalFieldHelp()" flat fab small outline color="black" class="my-4" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-money-bill fa-2x mt-0"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field
                                class="pa-0 mt-0"
                                id="withdraw_fee" ref="withdraw_fee" v-model="withdraw_fee"
                                label="Withdrawal fees (F CFA)" 
                                disabled
                                color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); withdraw_fee_help = true; getWithdrawalFieldHelp()" flat fab small outline color="black" class="my-3" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-phone fa-2x mt-0"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field
                                class="pa-0 mt-0"
                                id="phone_number" ref="phone_number" v-model="phone_number"
                                label="Phone Number to Receive Money"
                                :rules="[v => !!v || 'Transaction Phone Number is required']"
                                required
                                color="teal"
                            ></v-text-field>
                        </v-flex>
                        <v-flex xs2 >
                            <v-btn @click.stop="$store.commit('activateDialog', true); withdraw_phone_help = true; getWithdrawalFieldHelp()" flat fab small outline color="black" class="my-3" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-wrench fa-2x mt-0"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-select
                            class="pt-0" v-model="transaction_operator"
                            :items="operators" 
                            :rules="[v => !!v || 'Transaction Operator is required']"
                            label="Transaction Operator"
                            required
                            color="teal"
                            ></v-select>
                        </v-flex>
                        <v-flex xs2>
                            <v-btn @click.stop="$store.commit('activateDialog', true); withdraw_operator_help = true; getWithdrawalFieldHelp()" flat fab small outline color="black" class="my-3" style="height:20px; width: 20px" >
                                <i class="fas fa-question"> </i>                            
                            </v-btn>
                        </v-flex>
                    </v-layout>
                </v-flex>
            </v-layout>
            <div class="text-xs-center">
                <v-btn class="white--text elevation-6" small round color="teal darken-2" @click="Dialog = true">
                    <span> Submit</span>
                </v-btn>
            </div>
        </v-card>

        <v-dialog width="500px" v-model="Dialog"> 
            <v-card> 
                <v-card-title class="headline primary darken-2 white--text" primary-title>Confirm Withdrawal</v-card-title> 
                <v-card-text class="pa-4">
                    <p class="text-xs-center mb-0">Please fill in your password for security reasons. For us to be sure you have the withdrawal rights for this account. </p>
                    <v-layout row>
                        <v-flex xs2 sm1>
                            <i class="teal--text fas fa-lock fa-2x mt-3"> </i>                            
                        </v-flex>
                        <v-flex xs9 sm10>
                            <v-text-field
                                class="pa-0 mt-2"
                                id="password" v-model="password" ref="password"
                                label="Enter Password"
                                :rules="[v => !!v || 'Your Password is required (at least 8 characters)']"
                                single-line
                                color="teal"
                                min="8"
                                :append-icon="e1 ? 'visibility' : 'visibility_off'"
                                @click:append="() => (e1 = !e1)"
                                :type="e1 ? 'password' : 'text'"
                                counter
                            ></v-text-field>
                        </v-flex>
                    </v-layout>
                </v-card-text>

                <v-card-actions class="px-3"> 
                    <v-btn color="teal" flat @click="Dialog = false" > Cancel </v-btn> 
                    <v-spacer></v-spacer> 
                    <a id="dial_transaction" >
                        <v-btn color="teal darken-2" class="white--text" @click="requestWithdrawal()"> Confirm </v-btn> 
                    </a>
                </v-card-actions> 
            </v-card> 
        </v-dialog> 

    </div>
</template>

<script>
    import axios from 'axios'

    export default {

        data() {
            return {
                Dialog: false,
                password: "",
                e1: true,

                withdraw_amount_help: false,
                withdraw_phone_help: false,
                withdraw_operator_help: false,
                withdraw_fee_help: "",
                transaction_operator: "",
                phone_number: "",
                withdraw_amount: "",
                withdraw_fee: "",
                payment_ref: "",
                campaign: [],
                operators: [],
            }
        },
        mounted(){
            // executes these after the page has been mounted
            this.getCampaignDetail();
            this.getOperators();
            this.getCampaignPaymentRef();
        },

        methods:{

            getWithdrawalFee(){
                console.log(Number(this.withdraw_amount))
                if ( 1000 <= Number(this.withdraw_amount) && Number(this.withdraw_amount) <= 5000){
                    this.withdraw_fee = String(150) + " F CFA"
                    console.log(this.withdraw_fee)
                }else if (5001 <= Number(this.withdraw_amount) && Number(this.withdraw_amount) <= 10000){
                    this.withdraw_fee = String(225) + " F CFA"
                }else if (10001 <= Number(this.withdraw_amount) && Number(this.withdraw_amount) <= 15000){
                    this.withdraw_fee = String(350) + " F CFA"
                }else if (15001 <= Number(this.withdraw_amount) && Number(this.withdraw_amount) <= 20000){
                    this.withdraw_fee = String(450) + " F CFA"
                }else if (20001 <= Number(this.withdraw_amount) && Number(this.withdraw_amount) <= 30000){
                    this.withdraw_fee = String(600) + " F CFA"
                }else if (30001 <= Number(this.withdraw_amount) && Number(this.withdraw_amount) <= 40000){
                    this.withdraw_fee = String(700) + " F CFA"
                }else if (40001 <= Number(this.withdraw_amount) && Number(this.withdraw_amount) <= 50000){
                    this.withdraw_fee = String(800) + " F CFA"
                }else if (50001 <= Number(this.withdraw_amount) && Number(this.withdraw_amount) <= 100000){
                    this.withdraw_fee = String(1000) + " F CFA"
                }else if (100001 <= Number(this.withdraw_amount) && Number(this.withdraw_amount) <= 200000){
                    this.withdraw_fee = String(1500) + " F CFA"
                }else {
                    this.withdraw_fee = String(parseInt((1.5 / 100) * Number(this.withdraw_amount))) + "F CFA"
                }
            },

            async getCampaignDetail(){
                // getting the principal campaign
                this.$store.commit('setIsLoading', true)

                await axios
                    .get('/api/v1/savings/get/user/campaign/detail/0/')
                    .then(response => {
                        this.campaign = response.data  // get the data and fill into campaigns
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
            async getOperators(){
                this.$store.commit('setIsLoading', true)

                await axios
                    .get('/api/v1/home/get/operators/info/')
                    .then(response => {
                        this.operators = response.data  // get the data and fill into operators
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },

            async getCampaignPaymentRef(){
                this.$store.commit('setIsLoading', true)
                console.log(this.campaign)
                
                await axios
                    .get('/api/v1/savings/create/user/withdrawal/campaign/payment_ref/')
                    .then(response => {
                        this.payment_ref = response.data
                        console.log(this.payment_ref, response.data)  
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
            getWithdrawalFieldHelp(){
                if (this.withdraw_amount_help){
                    this.withdraw_amount_help = false
                    this.$store.commit('setDialogTitle', "Withdrawal Amount")
                    this.$store.commit('setDialogMessage', "Please the amount you want to withdraw.")
                    this.$store.commit('activateDialog', true)
                }else if (this.withdraw_phone_help){
                    this.withdraw_date_help = false
                    this.$store.commit('setDialogTitle', "Withdrawal Contact")
                    this.$store.commit('setDialogMessage', "Please input the contact you want the money sent to (MTN Momo, Orange Money, Yup, Express Union, Western Union).")
                    this.$store.commit('activateDialog', true)
                }else if (this.withdraw_fee_help){
                    this.withdraw_fee_help = false
                    this.$store.commit('setDialogTitle', "Transaction Fee")
                    this.$store.commit('setDialogMessage', "This displays the fees/charges you will have to pay for the transaction.")
                    this.$store.commit('activateDialog', true)
                }else if (this.withdraw_operator_help){
                    this.withdraw_operator_help = false
                    this.$store.commit('setDialogTitle', "Transaction Operator")
                    this.$store.commit('setDialogMessage', "Choose the operator through which the transaction should be made, either MTN, Orange, Express Union, Western Union, Bank transactions etc.")
                    this.$store.commit('activateDialog', true)
                }
            },

            async requestWithdrawal(){
                if(this.withdraw_amount === "" || String(Number(this.withdraw_amount)) === "NaN"){
                    if(this.withdraw_amount === ""){
                        this.$store.commit('setSnackBarMessage', "Please input the amount you want to withdraw")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.withdraw_amount.focus()  // this causes a an auto fucos to the element
                    }
                    else{
                        this.$store.commit('setSnackBarMessage', "Please input only numbers for the amount")
                        this.$store.commit('setSnackBarColor', "red darken-4")
                        this.$store.commit('activateSnackBar', true)
                        this.$refs.withdraw_amount.focus()  // this causes a an auto fucos to the element
                    }
                    this.Dialog = false
                }
                else if(this.phone_number.length < 8){
                    this.$store.commit('setSnackBarMessage', "Please input a correct phone number")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.phone_number.focus()  // this causes a an auto fucos to the element
                    this.Dialog = false
                }
                else if(this.password === ""){
                    this.$store.commit('setSnackBarMessage', "Please input your password.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                }
                else if(this.transaction_operator === ""){
                    this.$store.commit('setSnackBarMessage', "Please select the operator through which you sent the money.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    this.$refs.transaction_operator.focus()  // this causes a an auto fucos to the element
                    this.Dialog = false
                }
                else if(this.payment_ref === ""){
                    this.$store.commit('setSnackBarMessage', "Please an error occured with transaction, please try again later.")
                    this.$store.commit('setSnackBarColor', "red darken-4")
                    this.$store.commit('activateSnackBar', true)
                    window.location.reload()  // to reload and get another payment reference. this is for secuirity reasons
                    this.Dialog = false
                }
                else {
                    this.$store.commit('setIsLoading', true)
                    let formData = new FormData()
                    formData.append('password', this.password)
                    formData.append('withdraw_amount', this.withdraw_amount)
                    formData.append('phone_number', this.phone_number)
                    formData.append('transaction_operator', this.transaction_operator)
                    formData.append('campaign_id', this.campaign.id)
                    formData.append('payment_ref', this.payment_ref)
                    formData.append('feature', "Njangi")
                    console.log(formData)
    
                    await axios
                        .post('/api/v1/savings/request/withdrawal/', formData)
                        .then(response => {
                            console.log(response.data)
                            this.$store.commit('setSnackBarMessage', response.data[0].text)
                            this.$store.commit('setSnackBarColor', response.data[0].color)
                            this.$store.commit('activateSnackBar', true)
                            this.$store.commit('setSnackBarTimeOut', 8000)  // set time out for snack bar to 6seconds and remember too reset after 
                            this.Dialog = false

                            var navigation = this.$router
                            var store = this.$store  // timers are noted for this
                            if (response.data[0].valid){
                                setTimeout(function(){
                                    window.location.reload()  // to reload and get another payment reference. this is for secuirity reasons
                                    // navigation.push('/savings/withdrawals/request/')  // redirect back to campaign
                                    store.commit('setSnackBarTimeOut', 4000)  // set time out for snack bar to 4 seconds and remember too reset after 
                                }, 8000)
                            }
                        })
                        .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                        })
                    this.$store.commit('setIsLoading', false)
                }

            },

        },
        

    }
</script>
